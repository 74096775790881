import React, { FormEvent, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import { LockOutlined, Visibility, VisibilityOff } from '@material-ui/icons'
import {
  Avatar,
  Box,
  Container,
  FormHelperText,
  Grid,
  Typography
} from '@material-ui/core'

import { appAuthRoutes } from '../common/appRoutes'

import { useBoolState } from '../hooks/commonHooks'
import { useMessages } from '../hooks/snackbarHooks'
import { useLogic, useStore } from '../hooks/storeHook'
import { useAuthFormStyles } from '../hooks/authFormStylesHook'
import { BootstrapInput } from './BootstrapInput'
import { useSettingsPageStyles } from '../hooks/settingsPageStylesHook'
import clsx from 'clsx'

export const LoginScreen = observer(() => {
  const formClasses = useAuthFormStyles()
  const classes = useSettingsPageStyles()

  const logic = useLogic()
  const {hasAuth} = useStore()

  const {showSuccess, showError} = useMessages()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [isLoading, setLoading, setFinished] = useBoolState()

  if (hasAuth) {
    return null
  }

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()

    setLoading()

    try {
      await logic.login(email, password)
      showSuccess('Вход выполнен!')
    } catch (e) {
      setFinished()
      showError('Не удалось войти')
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <div className={formClasses.innerContainer}>
        <Box className={formClasses.avatarContainer} display="flex" flexDirection="row" alignItems="center">
          <Avatar className={formClasses.avatar}>
            <LockOutlined/>
          </Avatar>

          <Typography className={formClasses.avatarText}>Вход</Typography>
        </Box>

        <Box className={clsx(formClasses.container, classes.settingsTopic, 'default-border')}>
          <form className={formClasses.form}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormHelperText className={classes.helperText}>
                  <span className={classes.dash}>—</span> Email
                  <span className={clsx(classes.star, classes.star)}>*</span>
                </FormHelperText>
                <BootstrapInput
                  id="email"
                  onChange={(e) => setEmail(e.target.value)}
                  inputProps={{'aria-label': 'Email'}}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <FormHelperText className={classes.helperText}>
                  <span className={classes.dash}>—</span> Пароль
                  <span className={clsx(classes.star, classes.star)}>*</span>
                </FormHelperText>
                <Box display="flex" alignItems="center">
                  <BootstrapInput
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                    onChange={(e) => setPassword(e.target.value)}
                    inputProps={{'aria-label': 'Пароль'}}
                    fullWidth
                  />
                  <Box
                    onClick={() => setShowPassword(!showPassword)}
                    className={formClasses.showPassword}
                  >
                    {showPassword ? <Visibility/> : <VisibilityOff/>}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>

        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <Box display="flex">
            <button
              className={clsx(classes.saveButton, 'default-button')}
              disabled={isLoading}
              onClick={onSubmit}
            >
              <p>{isLoading ? '...' : 'Войти'}</p>
            </button>
          </Box>
          <Box marginTop={2}>
            <NavLink exact to={appAuthRoutes.Register} className={formClasses.formSignInLink}>
              Регистрация
            </NavLink>
          </Box>
          <Box marginTop={2}>
            <NavLink exact to={appAuthRoutes.PasswordReset} className={formClasses.formForgotPasswordLink}>
              Забыли пароль?
            </NavLink>
          </Box>
        </Box>
      </div>
    </Container>
  )
})