import React from 'react'
import { observer } from 'mobx-react-lite'
import { NavLink } from 'react-router-dom'

import { LockOutlined } from '@material-ui/icons'
import { Avatar, Box, Container, Typography } from '@material-ui/core'

import { PasswordResetForm } from './PasswordResetForm'

import { useAuthFormStyles } from '../hooks/authFormStylesHook'

import { appAuthRoutes } from '../common/appRoutes'

export const PasswordResetScreen =
  observer(() => {
    const formClasses = useAuthFormStyles()

    return (
      <Container component="main" maxWidth="xs">
        <div className={formClasses.innerContainer}>
          <Box className={formClasses.avatarContainer} display="flex" flexDirection="row" alignItems="center">
            <Avatar className={formClasses.avatar}>
              <LockOutlined/>
            </Avatar>

            <Typography className={formClasses.avatarText}>Восстановление пароля</Typography>
          </Box>

          <PasswordResetForm/>
        </div>

        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" margin={2}>
          <NavLink exact to={appAuthRoutes.Login} className={formClasses.formSignInLink}>
            Вход
          </NavLink>
        </Box>
      </Container>
    )
  })