import React, { RefObject, useMemo } from 'react'
import { SnackbarProvider, useSnackbar } from 'notistack'
import { Box } from '@material-ui/core'

import warningIcon from '../assets/warning.svg'
import errorIcon from '../assets/close.svg'
import successIcon from '../assets/check.svg'
import { makeStyles } from '@material-ui/core/styles'

const infoDurationMs = 5000
const errorDurationMs = 10000

export const useMessages =
  () => {
    const snackbar = useSnackbar()

    return useMemo(
      () => ({
        ...snackbar,

        showInfo:
          (message: string) =>
            snackbar.enqueueSnackbar(
              <MessageComponent message={message} icon={warningIcon}/>,
              {variant: 'info', autoHideDuration: infoDurationMs}),

        showSuccess:
          (message: string) =>
            snackbar.enqueueSnackbar(
              <MessageComponent message={message} icon={successIcon}/>,
              {variant: 'success', autoHideDuration: infoDurationMs}),

        showWarning:
          (message: string) =>
            snackbar.enqueueSnackbar(
              <MessageComponent message={message} icon={warningIcon}/>,
              {variant: 'warning', autoHideDuration: infoDurationMs}),

        showError:
          (message: string) =>
            snackbar.enqueueSnackbar(
              <MessageComponent message={message} icon={errorIcon}/>,
              {variant: 'error', autoHideDuration: errorDurationMs})
      }),
      [snackbar])
  }

export const showUiError =
  (snackbarRef: RefObject<SnackbarProvider>, uiMessage: string) =>
    snackbarRef.current?.enqueueSnackbar(
      <MessageComponent message={uiMessage} icon={errorIcon}/>,
      {variant: 'error', autoHideDuration: errorDurationMs})

interface MessageComponentProps {
  message: string,

  icon: string
}

const MessageComponent = ({message, icon}: MessageComponentProps) => {
  const classes = useStyles()

  return <Box className={classes.container}>
    <img src={icon} className={classes.icon} alt={'icon'}/>
    <span>{message}</span>
  </Box>
}

const useStyles = makeStyles(
  () => ({
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    icon: {
      marginRight: 10,
      width: 17,
      height: 17
    }
  }))