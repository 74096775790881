import React from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import { AppNotificationBar } from './AppNotificationBar'

import { useStore } from '../hooks/storeHook'

import { appRoutes } from '../common/appRoutes'
import { makeYandexMarketAccountName } from '../common/accountUtils'

import { UserAuthModel } from '../server/mpsklad_core/Models/UserAuthModel'

export const YandexMarketNewApiKeyFormatNotificationBars =
  observer(() => {
    const {user, syncStore: {yandexMarketAccounts}} = useStore()

    const accountsUsingOldTokenFormat =
      yandexMarketAccounts.filter(_ => !_.isUsingNewApiKeyFormat)

    if (!user || accountsUsingOldTokenFormat.length === 0) {
      return null
    }

    return <>
      {
        accountsUsingOldTokenFormat.map(
          account =>
            <AppNotificationBar
              severity="warning"
              notificationId={createNotificationId(user, account.id)}
            >
              {/* @formatter:off */}
              Яндекс.Маркет объявил OAuth-формат API токенов устаревшим. Советуем вам перейти на новый формат API
              токена <Link to={appRoutes.CommonStore.settings.route({integration: 'YandexMarket', accountId: account.id})}>
                в настройках аккаунта {makeYandexMarketAccountName(account)}.
              </Link>
            {/* @formatter:on */}
            </AppNotificationBar>)
      }
    </>
  })

const createNotificationId =
  // Email is used to show notifications to admins. Id for per-account notifications.
  (user: UserAuthModel, yandexMarketAccountId: number) =>
    `ymNewApiKeyFormat_${yandexMarketAccountId}_${user.email}`