import React, { FormEvent, useState } from 'react'
import { observer } from 'mobx-react-lite'

import { Box, FormHelperText, Grid } from '@material-ui/core'

import { useLogic } from '../hooks/storeHook'
import { useBoolState } from '../hooks/commonHooks'
import { useMessages } from '../hooks/snackbarHooks'
import { useAuthFormStyles } from '../hooks/authFormStylesHook'
import clsx from 'clsx'
import { useSettingsPageStyles } from '../hooks/settingsPageStylesHook'
import { BootstrapInput } from './BootstrapInput'
import { Visibility, VisibilityOff } from '@material-ui/icons'

export const PasswordResetNewPasswordForm =
  observer(() => {
    const formClasses = useAuthFormStyles()
    const classes = useSettingsPageStyles()

    const logic = useLogic()

    const {showSuccess} = useMessages()

    const [newPassword, setNewPassword] = useState('')
    const [passwordConfirmed, setPasswordConfirmed] = useState(true)
    const [isLoading, setLoading, setFinished] = useBoolState()
    const [showPassword, setShowPassword] = useState(false)
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false)

    const onSubmit =
      async (e: FormEvent) => {
        e.preventDefault()

        setLoading()

        try {
          await logic.resetPassword(newPassword)
          showSuccess('Пароль успешно изменён!')
        } catch (e) {
          // NOTE: Don't update state on success - this component will be unmounted
          setFinished()
        }
      }

    return (
      <>
        <Box className={clsx(formClasses.container, classes.settingsTopic, 'default-border')}>
          <form className={formClasses.form} onSubmit={onSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormHelperText className={classes.helperText}>
                  <span className={classes.dash}>—</span>
                  Пароль
                  <span className={clsx(classes.star, classes.star)}>*</span>
                </FormHelperText>
                <Box display="flex" alignItems="center">
                  <BootstrapInput
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                    onChange={(e) => setNewPassword(e.target.value)}
                    inputProps={{'aria-label': 'Пароль'}}
                    fullWidth
                  />
                  <Box
                    onClick={() => setShowPassword(!showPassword)}
                    className={formClasses.showPassword}
                  >
                    {showPassword ? <Visibility/> : <VisibilityOff/>}
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <FormHelperText className={classes.helperText}>
                  <span className={classes.dash}>—</span>
                  Подтверждение пароля
                  <span className={clsx(classes.star, classes.star)}>*</span>
                </FormHelperText>
                <Box display="flex" alignItems="center">
                  <BootstrapInput
                    id="password-confirm"
                    type={showPasswordConfirm ? 'text' : 'password'}
                    error={!passwordConfirmed}
                    onChange={(e) => setPasswordConfirmed(e.target.value === newPassword)}
                    inputProps={{'aria-label': 'Подтверждение пароля'}}
                    fullWidth
                  />
                  <Box
                    onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
                    className={formClasses.showPassword}
                  >
                    {showPasswordConfirm ? <Visibility/> : <VisibilityOff/>}
                  </Box>
                </Box>
                {!passwordConfirmed && (
                  <FormHelperText className={classes.helperText} style={{color: 'red'}}>
                    Пароли должны совпадать
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
          </form>
        </Box>

        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" mt={2}>
          <button
            className={clsx(classes.saveButton, 'default-button')}
            disabled={isLoading}
            onClick={onSubmit}>
            <p>{isLoading ? '...' : 'Изменить пароль'}</p>
          </button>
        </Box>
      </>
    )
  })