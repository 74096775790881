import React, { FormEvent, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import { LockOutlined, Visibility, VisibilityOff } from '@material-ui/icons'
import {
  Avatar,
  Box,
  Container,
  Grid,
  Typography,
  FormHelperText
} from '@material-ui/core'

import { PhoneNumberInput } from './PhoneNumberInput'

import { useBoolState } from '../hooks/commonHooks'
import { useMessages } from '../hooks/snackbarHooks'
import { useLogic, useStore } from '../hooks/storeHook'
import { useAuthFormStyles } from '../hooks/authFormStylesHook'

import { __PRODUCTION__ } from '../common/devUtils'
import { appAuthRoutes } from '../common/appRoutes'
import { useSettingsPageStyles } from '../hooks/settingsPageStylesHook'
import clsx from 'clsx'

import { BootstrapInput } from './BootstrapInput'
import { CircleCheckbox } from './CircleCheckbox'

export const RegisterScreen = observer(() => {
  const formClasses = useAuthFormStyles()
  const classes = useSettingsPageStyles()

  const logic = useLogic()
  const {hasAuth} = useStore()

  const {showSuccess, showError} = useMessages()

  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirmed, setPasswordConfirmed] = useState(true)
  const [acceptTermsOfService, setAcceptTermsOfService] = useState(false)

  const [isLoading, setLoading, setFinished] = useBoolState()

  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false)

  if (hasAuth) {
    return null
  }

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()

    if (!acceptTermsOfService) {
      showError('Необходимо принять оферту.')
      return
    }

    setLoading()

    try {
      await logic.register({email, password, name, phone})

      showSuccess('Вы зарегистрированы!')
    } catch (e) {
      setFinished()
      showError('Не удалось зарегистрироваться')
      return
    }

    if (__PRODUCTION__) {
      ym(69917182, 'reachGoal', 'successRegistration')
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <div className={formClasses.innerContainer}>
        <Box className={formClasses.avatarContainer} display="flex" flexDirection="row" alignItems="center">
          <Avatar className={formClasses.avatar}>
            <LockOutlined/>
          </Avatar>

          <Typography className={formClasses.avatarText}>Регистрация</Typography>
        </Box>

        <Box className={clsx(formClasses.container, classes.settingsTopic, 'default-border')}>
          <form className={formClasses.form}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormHelperText className={classes.helperText}>
                  <span className={classes.dash}>—</span>
                  Имя
                  <span className={clsx(classes.star, classes.star)}>*</span>
                </FormHelperText>
                <BootstrapInput
                  id="firstName"
                  onChange={(e) => setName(e.target.value)}
                  inputProps={{'aria-label': 'Имя'}}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <FormHelperText className={classes.helperText}>
                  <span className={classes.dash}>—</span>
                  Телефон
                </FormHelperText>
                <BootstrapInput
                  id="phone"
                  inputComponent={PhoneNumberInput}
                  inputProps={{
                    'aria-label': 'Телефон',
                    onAccept: setPhone
                  }}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <FormHelperText className={classes.helperText}>
                  <span className={classes.dash}>—</span>
                  Email
                  <span className={clsx(classes.star, classes.star)}>*</span>
                </FormHelperText>
                <BootstrapInput
                  id="email"
                  onChange={(e) => setEmail(e.target.value)}
                  inputProps={{'aria-label': 'Email'}}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <FormHelperText className={classes.helperText}>
                  <span className={classes.dash}>—</span>
                  Пароль
                  <span className={clsx(classes.star, classes.star)}>*</span>
                </FormHelperText>
                <Box display="flex" alignItems="center">
                  <BootstrapInput
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                    onChange={(e) => setPassword(e.target.value)}
                    inputProps={{'aria-label': 'Пароль'}}
                    fullWidth
                  />
                  <Box
                    onClick={() => setShowPassword(!showPassword)}
                    className={formClasses.showPassword}
                  >
                    {showPassword ? <Visibility/> : <VisibilityOff/>}
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <FormHelperText className={classes.helperText}>
                  <span className={classes.dash}>—</span>
                  Подтверждение пароля
                  <span className={clsx(classes.star, classes.star)}>*</span>
                </FormHelperText>
                <Box display="flex" alignItems="center">
                  <BootstrapInput
                    id="password-confirm"
                    type={showPasswordConfirm ? 'text' : 'password'}
                    error={!passwordConfirmed}
                    onChange={(e) => setPasswordConfirmed(e.target.value === password)}
                    inputProps={{'aria-label': 'Подтверждение пароля'}}
                    fullWidth
                  />
                  <Box
                    onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
                    className={formClasses.showPassword}
                  >
                    {showPasswordConfirm ? <Visibility/> : <VisibilityOff/>}
                  </Box>
                </Box>
                {!passwordConfirmed && (
                  <FormHelperText className={classes.helperText} style={{color: 'red'}}>
                    Пароли должны совпадать
                  </FormHelperText>
                )}
              </Grid>

              <Grid item xs={12}>
                <CircleCheckbox
                  checked={acceptTermsOfService}
                  onChange={({target: {checked}}) => setAcceptTermsOfService(checked)}
                  label={
                    <span className={formClasses.linkContainer}>
                      Регистрируясь, я соглашаюсь с{' '}
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://mp360.ru/img/rules.pdf"
                        className={formClasses.formLink}
                      >
                        офертой
                      </a>
                      .
                    </span>}/>
              </Grid>
            </Grid>
          </form>
        </Box>

        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <Box display="flex">
            <button
              className={clsx(classes.saveButton, 'default-button')}
              disabled={isLoading}
              onClick={onSubmit}>
              <p>{isLoading ? '...' : 'Зарегистрироваться'}</p>
            </button>
          </Box>

          <NavLink exact to={appAuthRoutes.Login} className={formClasses.formSignInLink}>
            Вход
          </NavLink>
        </Box>
      </div>
    </Container>
  )
})