import React from 'react'
import { observer } from 'mobx-react-lite'

import { Box } from '@material-ui/core'

import { MyProductsColumnCheckbox } from './MyProductsColumnCheckbox'

import { useStore } from '../hooks/storeHook'

import { MyProductColumnGroupId, myProductColumnGroupNames } from '../types/myProductColumnGroups'
import { makeStyles } from '@material-ui/core/styles'

export const MyProductsColumnItem =
  observer(
    ({groupId}: {groupId: MyProductColumnGroupId}) => {
      const classes = useStyles()

      const {myProductColumnsGrouped} = useStore()

      const columnGroup = myProductColumnsGrouped[groupId]

      return (
        <>
          <Box>
            <Box marginLeft={1} style={{marginBottom: 12}}>
              <span className={classes.dash}>—</span>
              <span className={classes.label}>{myProductColumnGroupNames[groupId]}</span>
            </Box>

            {
              columnGroup.filter(column => column.id !== 'Select')
                         .map(column => <MyProductsColumnCheckbox key={column.id} column={column}/>)
            }
          </Box>

        </>
      )
    })

const useStyles = makeStyles(
  (theme) => ({
    dash: {
      color: '#3987CF'
    },
    label: {
      fontSize: 14,
      color: theme.palette.mpSkladPrimary.gray,
      marginLeft: 5
    }
  }))