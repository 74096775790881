import { makeStyles } from '@material-ui/core/styles'

export const useAuthFormStyles =
  makeStyles(
    theme => ({
      innerContainer: {
        // Padding instead of margin fixes unnecessary scrollbar on 768p
        paddingTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      },
      avatar: {
        color: theme.palette.mpSkladPrimary.blue,
        margin: theme.spacing(1),
        backgroundColor: '#FFF',
        height: 24,
        width: 24
      },
      avatarText: {
        fontSize: 30,
        fontWeight: 700,
        color: theme.palette.mpSkladPrimary.blue
      },
      avatarContainer: {
        marginBottom: 40
      },
      container: {
        padding: 24
      },
      showPassword: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        border: '1px solid #ced4da',
        borderRadius: 8,
        backgroundColor: '#FFF',
        padding: 7,
        marginLeft: 10,
        color: theme.palette.mpSkladPrimary.blue
      },
      form: {
        // Fix IE 11 issue
        width: '100%',
        marginTop: theme.spacing(1)
      },
      submit: {
        margin: theme.spacing(3, 0, 2)
      },
      formLink: {
        color: theme.palette.mpSkladSecondary.darkBlue2,
        textDecoration: 'none'
      },
      linkContainer: {
        fontWeight: 700,
        fontSize: 12,
        color: `${theme.palette.mpSkladSecondary.darkBlue1} !important`
      },
      formSignInLink: {
        fontSize: 10,
        color: theme.palette.mpSkladPrimary.blue,
        textDecoration: 'none',
        '&:hover, &:focus, &:active': {
          color: theme.palette.mpSkladPrimary.blue
        }
      },
      formForgotPasswordLink: {
        fontSize: 10,
        color: '#727272',
        textDecoration: 'none',
        '&:hover, &:focus, &:active': {
          color: '#727272'
        }
      }
    }))