import React from 'react'
import { observer } from 'mobx-react-lite'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import { Box, FormLabel, Grid } from '@material-ui/core'

import { useBoolState } from '../hooks/commonHooks'
import { useMessages } from '../hooks/snackbarHooks'
import { useApi, useStore } from '../hooks/storeHook'

export const MoySkladWebhookPanel =
  observer(
    () => {
      const {showSuccess, showError} = useMessages()

      const {syncStore} = useStore()

      const api = useApi()
      const [isLoading, setIsLoading, setNotLoading] = useBoolState()
      const classes = useStyles()

      const onVerifyWebhooks = async () => {
        setIsLoading()

        try {
          await api.userSync.verifyMoySkladWebhooks()
          showSuccess('Вебхуки проверены успешно!')
        } catch (e) {
          console.error('Failed to verify MoySklad webhooks', e)
          showError('Не удалось проверить')
        } finally {
          setNotLoading()
        }
      }

      if (!syncStore.moySkladAccount) {
        return null
      }

      return (
        <Box className={classes.pageContainer}>
          <Grid
            container
            justifyContent="space-between"
            className={clsx(classes.settingsTopic, classes.root, 'default-border')}>
            <Grid item xs={6} alignContent="center">
              <FormLabel className={classes.label}>
                Отслеживание статуса заказа
              </FormLabel>
            </Grid>

            <Grid item xs="auto">
              <button
                disabled={isLoading}
                className={clsx(classes.saveButton, 'default-button')}
                onClick={onVerifyWebhooks}>
                <p>
                  {
                    isLoading ? 'Проверяем...' : 'Проверить статус вебхука'
                  }
                </p>
              </button>
            </Grid>
          </Grid>
        </Box>
      )
    })

const useStyles = makeStyles(
  () => ({
    pageContainer: {
      margin: '0 40px 20px 0px'
    },
    root: {
      padding: 15
    },
    settingsTopic: {
      borderRadius: 12
    },
    label: {
      fontSize: 12,
      fontWeight: 600,
      color: '#1F364D'
    },
    saveButton: {
      width: 250,
      height: 40,
      color: '#FFFFFF',
      alignItems: 'center',
      marginTop: 10
    }
  }))