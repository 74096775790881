import React, { FormEvent, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
import clsx from 'clsx'

import { Box, FormHelperText, FormLabel, Grid, Typography } from '@material-ui/core'

import { LabeledInput } from '../../LabeledInput'
import { LabeledIOSSwitch } from '../../LabeledIOSSwitch'
import { DefaultRadioGroup } from '../../DefaultRadioGroup'
import { SyncStartTableRow } from '../../SyncStartTableRow'
import { YandexMarketLoadOrdersForm } from '../../YandexMarketLoadOrdersForm'

import { useMessages } from '../../../hooks/snackbarHooks'
import { useYandexMarketForm } from './hooks/useYandexMarketForm'
import { useApi, useLogic, useStore } from '../../../hooks/storeHook'
import { useRadioGroupStyles } from '../../../hooks/radioGroupStylesHook'
import { useSettingsPageStyles } from '../../../hooks/settingsPageStylesHook'

import { appRoutes } from '../../../common/appRoutes'
import { required } from '../../../common/objectUtils'
import { formatDateOnlyUnix } from '../../../common/dateTimeUtility'

import { CommissionsRecordingType } from '../../../server/mpsklad_core/Entity/Base/CommissionsRecordingType'
import { EditYandexMarketAccountModel } from '../../../server/mpsklad_core/Models/EditYandexMarketAccountModel'
import { YandexMarketDeliveryType } from '../../../server/mpsklad_core/Entity/YandexMarket/Enums/YandexMarketDeliveryType'

export const YandexMarketApiSettings =
  observer(
    () => {
      const classes = useSettingsPageStyles()
      const radioGroupClasses = useRadioGroupStyles()

      const getDeliveryTypeClasses =
        (checked: boolean) => clsx(radioGroupClasses.radioButton, checked && radioGroupClasses.selectedRadioButton)

      const {showSuccess, showError} = useMessages()

      const logic = useLogic()
      const store = useStore()

      const account = logic.tryGetYandexMarketAccount(store.homeNavRequired.accountId)

      const history = useHistory()

      const {userSync: {setYandexMarketSyncStartDate}} = useApi()

      const {
        apiKey,
        setApiKey,
        isUsingNewApiKeyFormat,
        toggleIsUsingNewApiKeyFormat,
        campaignId,
        setCampaignId,
        businessId,
        setBusinessId,
        userCode,
        setUserCode,
        name,
        setName,
        usingUnifiedPrices,
        toggleUsingUnifiedPrices,
        deliveryType,
        setDeliveryType,
        isLoading,
        setIsLoading
      } = useYandexMarketForm(account)

      const deliveryTypeOptions =
        useMemo(() => [{
            value: YandexMarketDeliveryType.FBY,
            label: 'Продажа со склада Yandex.Market (FBY)'
          }, {
            value: YandexMarketDeliveryType.FBS,
            label: 'Продажа со своего склада (FBS)'
          }, {
            value: YandexMarketDeliveryType.Express,
            label: 'Продажа со своего склада с доставкой силами Yandex.Market (Express)'
          }, {
            value: YandexMarketDeliveryType.DBS,
            label: 'Продажа со своего склада и доставка силами продавца (DBS)'
          }],
          [])

      const onSubmit =
        async (e: FormEvent) => {
          e.preventDefault()

          if (!account?.tokenExpiryTimeUnix && !userCode && !isUsingNewApiKeyFormat) {
            showError('Необходимо ввести код подтверждения.')
            return
          }

          if (isUsingNewApiKeyFormat && !apiKey.trim()) {
            showError('Необходимо ввести токен API.')
            return
          }

          setIsLoading(true)

          try {
            const formData = {
              apiKey,
              isUsingNewApiKeyFormat,
              campaignId: parseInt(campaignId),
              userCode: userCode ? parseInt(userCode) : undefined,
              usingUnifiedPrices,
              deliveryType,
              isUpdatePrices: account?.isUpdatePrices ?? false,
              isGeneratingPayments: account?.isGeneratingPayments ?? false,
              isUsingCommissions: account?.isUsingCommissions ?? false,
              ordersCommissionsRecording: account?.ordersCommissionsRecording ?? CommissionsRecordingType.Nothing,
              documentsCommissionsRecording: account?.documentsCommissionsRecording ?? CommissionsRecordingType.Nothing,
              name: name || undefined,
              msSalesReturnStoreId: account?.msSalesReturnStoreId,
              msData: {
                msOrganization: account?.msOrganization ?? '',
                msCounterparty: account?.msCounterparty ?? '',
                msContractId: account?.msContractId || undefined,
                msSalesChannelId: account?.msSalesChannelId || undefined,
                msProjectId: account?.msProjectId || undefined
              }
            } satisfies Omit<EditYandexMarketAccountModel, 'id'>

            if (account) {
              await logic.editYandexMarketAccount({id: account.id, ...formData})
            } else {
              const newAccount = await logic.createYandexMarketAccount({id: 0, ...formData})

              history.push(
                appRoutes.CommonStore.settings.api.route({accountId: newAccount.id, integration: 'YandexMarket'}))
            }

            setUserCode('')
            showSuccess('Сохранено!')
          } catch (e) {
            console.error('Failed to save YandexMarket account', e)
            showError('Ошибка при сохранении аккаунта')
          } finally {
            setIsLoading(false)
          }
        }

      return (
        <Box className={classes.pageContainer}>
          <Typography className={classes.header}>Настройки API</Typography>

          <form onSubmit={onSubmit}>
            <Grid container spacing={3}>
              {/* Left column */}
              <Grid item xs={12} md={6} style={account ? {minWidth: '50%'} : {}}>
                <Grid container spacing={3}>
                  {/* Campaign ID, Business ID and User Code in one row */}
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
                          <Grid item xs={12} className={classes.root}>
                            <LabeledInput
                              required
                              id="campaignId"
                              placeholder="1337"
                              label="Номер компании"
                              value={campaignId}
                              onChange={setCampaignId}
                            />
                          </Grid>

                          <Grid item xs={12} className={classes.root}>
                            <LabeledInput
                              readOnly
                              id="bussinesId"
                              label="Идентификатор бизнеса"
                              value={businessId || 'Значение появится при успешном добавлении аккаунта'}
                              onChange={setBusinessId}
                            />
                          </Grid>

                          <Grid item xs={12} className={classes.root}>
                            <LabeledIOSSwitch
                              label="Настройка API токена"
                              switchLabel="Использовать новый формат API токена (Api-Key)"
                              checked={isUsingNewApiKeyFormat}
                              onChange={toggleIsUsingNewApiKeyFormat}
                              helperText={
                                isUsingNewApiKeyFormat
                                ? undefined
                                : 'Не рекомендуем использовать старый формат API токена (OAuth-токен), так как этот способ авторизации устарел. Вскоре его поддержка будет отключена.'
                              }
                            />
                          </Grid>

                          {
                            isUsingNewApiKeyFormat
                            ?
                            <Grid item xs={12} className={classes.root}>
                              <LabeledInput
                                id="apiKey"
                                label="Токен API"
                                value={apiKey}
                                onChange={setApiKey}
                                helperText='Получить токен API можно в личном кабинете Яндекс.Маркета в разделе "Обмен данными" -> "Модули и API" -> "Авторизационные токены".'
                              />
                            </Grid>
                            :
                            account?.tokenExpiryTimeUnix == null
                            ?
                            <Grid item xs={12} className={classes.root}>
                              <LabeledInput
                                required
                                id="userCode"
                                placeholder="12227"
                                label="Код подтверждения"
                                value={userCode}
                                onChange={setUserCode}
                                helperText={<>
                                  {/* @formatter:off */}
                                  Чтобы получить код, перейдите&nbsp;
                                  <a href="https://oauth.yandex.ru/authorize?response_type=code&client_id=bf8796a163424ce2b6ee8124a3a84a5b" target="_blank" rel="noopener noreferrer">по данной ссылке</a>.
                                  Код активен 10 минут, после чего генерировать его придётся снова.
                                  {/* @formatter:on */}
                                </>}
                              />
                            </Grid>
                            :
                            <>
                              <Grid item xs={12} className={classes.root}>
                                <LabeledInput
                                  readOnly
                                  label="Токен API"
                                  value={required(account.apiKey)}
                                />
                              </Grid>

                              <Grid item xs={12} className={classes.root}>
                                <LabeledInput
                                  readOnly
                                  label="Срок службы токена API"
                                  value={formatDateOnlyUnix(account.tokenExpiryTimeUnix)}
                                />
                              </Grid>
                            </>
                          }

                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Account Name */}
                  <Grid item xs={12}>
                    <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
                      <Grid item className={classes.root} xs={12}>
                        <LabeledInput
                          required
                          id="campaignId"
                          placeholder="Yandex Market 1000"
                          label="Название аккаунта"
                          value={name}
                          onChange={setName}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Schema of Work */}
                  <Grid item xs={12}>
                    <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
                      <Grid item xs={12} className={classes.root}>
                        <DefaultRadioGroup
                          name="ModelType"
                          label="Схема работы"
                          value={deliveryType}
                          options={deliveryTypeOptions}
                          onChange={setDeliveryType}
                          getOptionClassName={getDeliveryTypeClasses}
                          optionLabelClassName={radioGroupClasses.radioLabel}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  {
                    account &&
                    <Grid item xs={12}>
                      <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
                        <Grid item xs={12} className={classes.root}>
                          <LabeledIOSSwitch
                            label="Настройка работ с ценами"
                            switchLabel="Использовать общие цены"
                            checked={usingUnifiedPrices}
                            onChange={toggleUsingUnifiedPrices}
                            helperText="Если ваша кампания использует разные цены в разных магазинах (стандартно для давно зарегистрированных магазинов), то пропустите этот этап."
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  }
                </Grid>
              </Grid>

              {/* Right column */}
              {
                account &&
                <Grid item xs={12} md={6}>
                  <Grid container spacing={3}>
                    {
                      account.ordersTrackStartUnix &&
                      <Grid item xs={12}>
                        <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
                          <Grid item xs={12} className={classes.root}>
                            <Box>
                              <FormLabel component="legend" className={classes.boldLabel}>
                                Дата начала синхронизации заказов
                              </FormLabel>
                            </Box>

                            <SyncStartTableRow account={account} onSubmit={setYandexMarketSyncStartDate}/>

                            <FormHelperText className={classes.helperText}>
                              Будут синхронизированы заказы, созданные позже этой даты.
                            </FormHelperText>
                          </Grid>
                        </Grid>
                      </Grid>
                    }

                    {
                      account.deliveryType === YandexMarketDeliveryType.FBY &&
                      <Grid item xs={12}>
                        <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
                          <Grid item xs={12} className={classes.root}>
                            <Box>
                              <FormLabel component="legend" className={classes.boldLabel}>
                                Загрузка заказов
                              </FormLabel>
                            </Box>

                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <YandexMarketLoadOrdersForm yandexMarketAccountId={account.id}/>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    }
                  </Grid>
                </Grid>
              }
            </Grid>

            <Box display="flex">
              <button
                className={clsx(classes.saveButton, 'default-button')}
                disabled={isLoading}
                onClick={onSubmit}
              >
                <p>{isLoading ? 'Сохраняем...' : 'Сохранить'}</p>
              </button>
            </Box>
          </form>
        </Box>
      )
    })