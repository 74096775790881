import { CSSProperties } from 'react'

import { AllowedIntegrationsMap } from './integrationTypeUtils'
import { MyProductColumnGroupId } from './myProductColumnGroups'
import { createColumnGroup, GroupedMyColumnsParam } from './columnGroupCreator'
import { MyProductColumnId, MyProductOnlyColumnId } from './myProductColumnIds'

import { TableDateCell } from '../components/tableCells/TableDateCell'
import { MyProductWbCell } from '../components/tableCells/MyProductWbCell'
import { MyProductNameCell } from '../components/tableCells/MyProductNameCell'
import { MyProductOzonCell } from '../components/tableCells/MyProductOzonCell'
import { MyProductSelectCell } from '../components/tableCells/MyProductSelectCell'
import { MyProductsSyncLogsCell } from '../components/tableCells/MyProductsSyncLogsCell'
import { MyProductSyncErrorsCell } from '../components/tableCells/MyProductSyncErrorsCell'
import { MyProductYandexMarketCell } from '../components/tableCells/MyProductYandexMarketCell'

import { msProductTypeNames } from '../store/logic/productUtils'

import { formatPriceOrEmpty } from '../common/numberUtils'

import { MyProductModel } from '../server/mpsklad_core/Models/MyProductModel'
import { IntegrationType } from '../server/mpsklad_core/Entity/IntegrationType'
import { MyProductsInfoModel } from '../server/mpsklad_core/Models/MyProductsInfoModel'

export const createMyStorageColumns =
  (homeHasStoreType: (storeType: IntegrationType) => boolean,
   info: MyProductsInfoModel | null,
   allowedIntegrations: AllowedIntegrationsMap) =>
    createColumnGroup<MyProductColumnId, MyProductModel>()(
      MyProductColumnGroupId.MyStorage, [{
        title: 'Выбрать товар',
        id: 'Select',
        CellComponent: MyProductSelectCell
      }, {
        title: 'Название',
        id: 'name',
        sortable: true,
        CellComponent: MyProductNameCell
      }, {
        title: 'Дата добавления',
        id: 'createdAt',
        sortable: true,
        CellComponent: TableDateCell
      }, {
        title: 'Ошибки',
        id: 'SyncErrors',
        CellComponent: MyProductSyncErrorsCell
      },
        homeHasStoreType(IntegrationType.Ozon) && allowedIntegrations[IntegrationType.Ozon] ? {
          title: 'Товар в Ozon',
          id: 'ConnectOzon',
          sortable: true,
          accessor: _ => _.ozonProducts[0]?.article,
          CellComponent: MyProductOzonCell
        } : null,
        homeHasStoreType(IntegrationType.Wildberries) && allowedIntegrations[IntegrationType.Wildberries] ? {
          title: 'Товар в Wildberries',
          id: 'ConnectWb',
          sortable: true,
          accessor: _ => _.wbProducts[0]?.chrtId,
          CellComponent: MyProductWbCell
        } : null,
        homeHasStoreType(IntegrationType.YandexMarket) && allowedIntegrations[IntegrationType.YandexMarket] ? {
          title: 'Товар в Yandex.Market',
          id: 'ConnectYandexMarket',
          sortable: true,
          accessor: _ => _.yandexMarketProducts[0]?.shopSku,
          CellComponent: MyProductYandexMarketCell
        } : null
        , {
          title: 'Код',
          id: 'code',
          sortable: true
        }, {
          title: 'Артикул',
          id: 'article',
          sortable: true
        }, {
          title: 'Тип',
          id: 'type',
          sortable: true,
          accessor: _ => msProductTypeNames[_.type]
        }, {
          title: 'Штрихкоды',
          id: 'barcodes',
          cellStyle: cellStyleBreakNormal
        }, {
          title: 'Цена',
          id: 'price',
          sortable: true,
          accessor: _ => formatPriceOrEmpty(_.price)
        }, {
          title: 'Цена без скидки',
          id: 'basePrice',
          sortable: true,
          accessor: _ => formatPriceOrEmpty(_.basePrice)
        },
        homeHasStoreType(IntegrationType.Ozon) && allowedIntegrations[IntegrationType.Ozon] ? {
          title: 'Цена Ozon минимальная',
          id: 'ozonMsMinimalPrice',
          sortable: true,
          accessor: _ => formatPriceOrEmpty(_.ozonMsMinimalPrice)
        } : null,
        homeHasStoreType(IntegrationType.Ozon) && allowedIntegrations[IntegrationType.Ozon] ? {
          title: 'Цена Ozon',
          id: 'ozonMsPrice',
          sortable: true,
          accessor: _ => formatPriceOrEmpty(_.ozonMsPrice)
        } : null,
        homeHasStoreType(IntegrationType.Ozon) && allowedIntegrations[IntegrationType.Ozon] ? {
          title: 'Цена Ozon без скидки',
          id: 'ozonMsBasePrice',
          sortable: true,
          accessor: _ => formatPriceOrEmpty(_.ozonMsBasePrice)
        } : null,
        homeHasStoreType(IntegrationType.Wildberries) && allowedIntegrations[IntegrationType.Wildberries] ? {
          title: 'Цена WB',
          id: 'wbMsPrice',
          sortable: true,
          accessor: _ => formatPriceOrEmpty(_.wbMsPrice)
        } : null,
        homeHasStoreType(IntegrationType.Wildberries) && allowedIntegrations[IntegrationType.Wildberries] ? {
          title: 'Цена WB без скидки',
          id: 'wbMsBasePrice',
          sortable: true,
          accessor: _ => formatPriceOrEmpty(_.wbMsBasePrice)
        } : null,
        homeHasStoreType(IntegrationType.YandexMarket) && allowedIntegrations[IntegrationType.YandexMarket] ? {
          title: 'Цена Yandex.Market',
          id: 'yandexMarketMsPrice',
          sortable: true,
          accessor: _ => formatPriceOrEmpty(_.yandexMarketMsPrice)
        } : null,
        homeHasStoreType(IntegrationType.YandexMarket) && allowedIntegrations[IntegrationType.YandexMarket] ? {
          title: 'Цена Yandex.Market без скидки',
          id: 'yandexMarketMsBasePrice',
          sortable: true,
          accessor: _ => formatPriceOrEmpty(_.yandexMarketMsBasePrice)
        } : null,
        // Don't extract a method - type checking can't handle it
        (homeHasStoreType(IntegrationType.Ozon) && allowedIntegrations[IntegrationType.Ozon]) ||
        (homeHasStoreType(IntegrationType.Wildberries) && allowedIntegrations[IntegrationType.Wildberries]) ||
        (homeHasStoreType(IntegrationType.YandexMarket) && allowedIntegrations[IntegrationType.YandexMarket])
        ? {
            title: 'Остаток в МоёмСкладе',
            id: 'totalMsBalance',
            accessor: _ => _.totalMsBalance
          }
        : null, {
          title: 'Логи',
          id: 'SyncLogs',
          CellComponent: MyProductsSyncLogsCell
        }] satisfies GroupedMyColumnsParam<MyProductOnlyColumnId, MyProductModel> // Ensure filtering of ids
    )

export const cellStyleBreakNormal =
  (): CSSProperties =>
    ({overflowWrap: 'normal'})