import { useState } from 'react'

import { YandexMarketAccountModel } from '../../../../server/mpsklad_core/Models/YandexMarketAccountModel'
import { YandexMarketDeliveryType } from '../../../../server/mpsklad_core/Entity/YandexMarket/Enums/YandexMarketDeliveryType'

export const useYandexMarketForm =
  (account: YandexMarketAccountModel | undefined) => {
    const [apiKey, setApiKey] = useState(account?.apiKey?.toString() ?? '')
    const [isUsingNewApiKeyFormat, setIsUsingNewApiKeyFormat] = useState(account?.isUsingNewApiKeyFormat ?? true)
    const [campaignId, setCampaignId] = useState(account?.campaignId?.toString() ?? '')
    const [businessId, setBusinessId] = useState(account?.businessId?.toString() ?? '')
    const [userCode, setUserCode] = useState('')
    const [name, setName] = useState(account?.name ?? 'Мой аккаунт')
    const [usingUnifiedPrices, setUsingUnifiedPrices] = useState(account?.usingUnifiedPrices ?? false)
    const [deliveryType, setDeliveryType] = useState(account?.deliveryType ?? YandexMarketDeliveryType.FBS)
    const [isLoading, setIsLoading] = useState(false)

    const toggleUsingUnifiedPrices = () => setUsingUnifiedPrices(prev => !prev)

    const toggleIsUsingNewApiKeyFormat = () => {
      setIsUsingNewApiKeyFormat(prev => !prev)

      if (!isUsingNewApiKeyFormat && !account?.isUsingNewApiKeyFormat) {
        // Prevent showing the old format API token in the new format field if the user flips the switch from the old format to the new one
        setApiKey('')
      } else {
        setApiKey(account?.apiKey?.toString() ?? '')
      }
    }

    return {
      apiKey,
      setApiKey,
      isUsingNewApiKeyFormat,
      toggleIsUsingNewApiKeyFormat,
      campaignId,
      setCampaignId,
      businessId,
      setBusinessId,
      userCode,
      setUserCode,
      name,
      setName,
      usingUnifiedPrices,
      toggleUsingUnifiedPrices,
      deliveryType,
      setDeliveryType,
      isLoading,
      setIsLoading
    }
  }