import { makeStyles } from '@material-ui/core/styles'

export const useOrderSearchStyles = makeStyles((theme) => ({
  root: {
    '& .MuiFormLabel-root': {
      color: '#D2D2D2',
      fontSize: 12,
      lineHeight: '14.4px'
    },
    '& .MuiInputLabel-formControl': {
      top: '2px'
    },
    '& .MuiFormHelperText-root': {
      color: theme.palette.mpSkladPrimary.gray
    },
    fontSize: 12
  },
  container: {
    marginTop: 10,
    marginBottom: 20,
    position: 'relative'
  }
}))