import { Checkbox, FormControlLabel } from '@material-ui/core'
import React from 'react'
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked'
import { CheckCircle } from '@material-ui/icons'
import clsx from 'clsx'
import { useCheckboxStyles } from '../hooks/checkboxStylesHook'

type CheckboxProps = {
  label: string | React.ReactNode;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

export const CircleCheckbox = ({label, checked, onChange, disabled}: CheckboxProps) => {
  const checkboxClasses = useCheckboxStyles()

  return (
    <FormControlLabel
      className={clsx(checkboxClasses.checkbox, (checked && !disabled) && checkboxClasses.checkedCheckbox)}
      label={label}
      control={
        <Checkbox
          disabled={disabled}
          color="primary"
          checked={checked}
          onChange={onChange}
          icon={<CircleUnchecked/>}
          checkedIcon={<CheckCircle/>}
          disableRipple
          disableFocusRipple
          disableTouchRipple
        />
      }
    />
  )
}