import { styled } from '@material-ui/core/styles'
import { InputBase } from '@material-ui/core'

export const BootstrapInput =
  styled(InputBase)(
    ({theme}) => ({
      width: '100%',
      '& .MuiInputBase-input': {
        borderRadius: 8,
        color: theme.palette.mpSkladSecondary.darkBlue1,
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 12,
        lineHeight: '14.4px',
        padding: '12px 12px',
        '&:focus': {
          borderColor: '#80bdff'
        }
      },
      '& .Mui-disabled': {
        backgroundColor: theme.palette.mpSkladPrimary.gray,
        opacity: 0.2,
        color: theme.palette.mpSkladSecondary.darkBlue1
      }
    }))