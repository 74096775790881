import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'

import { Box, FormLabel, Grid, makeStyles } from '@material-ui/core'

import { formatDateOnly } from '../common/dateTimeUtility'
import { AmountInsertStyle, pluralize } from '../common/stringUtility'

import { BootstrapInput } from './BootstrapInput'
import { OrderShipmentInfo } from '../common/labelsUtil'

/**
 * @property {OrdersAtDateCount[]} daysCounts dates must be unique
 */
export interface LabelsPostDateFiltersProps {
  overallCount: number,

  shipmentDateInfo: OrderShipmentInfo,

  onFilter(date: Date | null): void;
}

export const minShipmentDate = new Date('2000')

export const maxShipmentDate = new Date('3000')

/**
 * List of buttons for filtering post's labels
 */
export const LabelsPostDateFilters =
  observer(
    ({overallCount, shipmentDateInfo, onFilter}: LabelsPostDateFiltersProps) => {
      const styles = useStyles()

      const [isFiltersActive, setFiltersActive] = useState<Boolean[]>([])

      const {daysCounts, laterCount, beforeCount} = shipmentDateInfo

      function pluralizeShipments(count: number): string {
        return pluralize(count, 'отправление', 'отправления', 'отправлений', AmountInsertStyle.None)
      }

      useEffect(() => {
        const blankArray = new Array(daysCounts.length + 3).fill(false)
        blankArray[0] = true
        setFiltersActive(blankArray)
      }, [daysCounts])

      /**
       * Changing filter button class to active
       */
      function onFilterSelect(filterIndex: number): void {
        setFiltersActive(
          isFiltersActive.map((_, i) => i === filterIndex)
        )
      }

      function onClickFilter(date: Date | null, indexToSelect: number): void {
        if (!isFiltersActive[indexToSelect]) {
          onFilter(date)
          onFilterSelect(indexToSelect)
        }
      }

      return (
        <Grid className={styles.container} item container xs={12} spacing={1}>
          <Grid item xs={'auto'}>

            <Box>
              <FormLabel component="legend" className={styles.label}>
                <span className={styles.dash}>—</span>
                Все отправления
              </FormLabel>
            </Box>

            <BootstrapInput
              onClick={() => onClickFilter(null, 0)}
              value={`${overallCount} ${pluralizeShipments(overallCount)}`}
              className={styles.input}
              inputProps={{className: (isFiltersActive[0] && styles.selected)}}
            />

          </Grid>

          {
            daysCounts.length > 0 && beforeCount > 0 &&
            <Grid item xs={'auto'}>

              <Box>
                <FormLabel component="legend" className={styles.label}>
                  <span className={styles.dash}>—</span>
                  Ранее
                </FormLabel>
              </Box>

              <BootstrapInput
                onClick={() => onClickFilter(minShipmentDate, 1)}
                value={`${beforeCount} ${pluralizeShipments(beforeCount)}`}
                className={styles.input}
                inputProps={{className: (isFiltersActive[1] && styles.selected)}}
              />

            </Grid>
          }

          {
            daysCounts.map(({date, count}, i) =>
              <Grid key={date.valueOf()} item xs={'auto'}>

                <Box>
                  <FormLabel component="legend" className={styles.label}>
                    <span className={styles.dash}>—</span>
                    {formatDateOnly(date)}
                  </FormLabel>
                </Box>

                <BootstrapInput
                  onClick={() => onClickFilter(date, i + 2)}
                  value={`${count} ${pluralizeShipments(count)}`}
                  className={styles.input}
                  inputProps={{className: (isFiltersActive[i + 2] && styles.selected)}}
                />

              </Grid>)
          }

          {
            daysCounts.length > 0 && laterCount > 0 &&
            <Grid item xs={'auto'}>

              <Box>
                <FormLabel component="legend" className={styles.label}>
                  <span className={styles.dash}>—</span>
                  Позднее
                </FormLabel>
              </Box>

              <BootstrapInput
                onClick={() => onClickFilter(maxShipmentDate, isFiltersActive.length - 1)}
                value={`${laterCount} ${pluralizeShipments(laterCount)}`}
                className={styles.input}
                inputProps={{className: (isFiltersActive[isFiltersActive.length - 1] && styles.selected)}}
              />

            </Grid>
          }
        </Grid>
      )
    })

export const useStyles = makeStyles(
  () => ({
    filter_Button: {
      textTransform: 'none'
    },
    container: {
      marginTop: 10
    },
    selected: {
      backgroundColor: '#E7F4FF !important',
      color: '#4A7DAC !important'
    },
    input: {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '14.4px !important',
      textAlign: 'left',
      '& .Mui-disabled': {
        color: '#1F364D'
      },
      caretColor: 'transparent',
      width: 'fit-content'
    },
    dash: {
      color: '#3987CF',
      marginRight: 3
    },
    label: {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '14.4px',
      textAlign: 'left',
      marginBottom: 15
    }
  }))