import { makeStyles } from '@material-ui/core/styles'

export const useDefaultMenuItemStyles =
  makeStyles((theme) => ({
    menuItem: {
      fontSize: '12px !important',
      fontWeight: 400,
      lineHeight: '12px',
      textAlign: 'left',
      backgroundColor: '#FAFAFA !important',
      color: theme.palette.mpSkladPrimary.gray,
      '&:hover': {
        color: '#4A7DAC',
        backgroundColor: '#FAFAFA'
      },
      '&.Mui-selected, &.Mui-selected:hover': {
        color: '#3987CF',
        backgroundColor: '#FAFAFA'
      },
      '&[aria-selected="true"]': {
        backgroundColor: '#FAFAFA',
        color: '#3987CF'
      },
      '&:focus': {
        color: '#1F364D',
        backgroundColor: '#FAFAFA'
      },
      '&:focusVisible': {
        backgroundColor: 'transparent'
      },
      '&:active': {
        backgroundColor: 'transparent'
      },
      '& .MuiTouchRipple-root': {
        color: 'transparent'
      }
    },
    iconButtonOutlined: {
      border: '1px solid #D2D2D2',
      borderRadius: '8px',
      height: 40,
      width: 40
    },
    menu: {
      position: 'absolute',
      top: '100%',
      left: 0,
      backgroundColor: '#FAFAFA',
      border: '1px solid #ced4da',
      borderRadius: 8,
      marginTop: 4,
      maxHeight: 200,
      boxShadow: '0px 3px 12px 0px #4B8FD226',
      minHeight: 36,
      zIndex: 5000,
      overflowY: 'auto'

    },
    inputWrapper: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center'
    },
    input: {
      '& .MuiInputBase-input:focus': {
        border: '1px solid rgb(206, 212, 218)'
      },
      '& .MuiInputBase-input': {
        minHeight: '40px',
        padding: '8px 12px',
        boxSizing: 'border-box',
        lineHeight: 'normal'
      }
    }
  }))