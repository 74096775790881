import React from 'react'
import { observer } from 'mobx-react-lite'

import { Box, CircularProgress } from '@material-ui/core'

import { StatusSyncTable } from './StatusSyncTable'

import { useLogic } from '../hooks/storeHook'
import { useMessages } from '../hooks/snackbarHooks'
import { useBoolState, useLazyEffect } from '../hooks/commonHooks'
import clsx from 'clsx'
import { useSettingsPageStyles } from '../hooks/settingsPageStylesHook'

export const StatusScreen =
  observer(() => {
    const logic = useLogic()

    const classes = useSettingsPageStyles()

    const {showSuccess} = useMessages()

    const [isRefreshing, setRefreshing, setRefreshed] = useBoolState()

    // No loader, silent update
    useLazyEffect(logic.loadUserAccounts)

    const onRefreshClick = async () => {
      try {
        setRefreshing()
        const {
          productsSuccess, productsTotal,
          pushMsSuccess, pushMsTotal,
          pullStocksSuccess, pullStocksTotal
        } = await logic.syncUser()

        showSuccess(`Получение остатков и заказов: ${pullStocksSuccess}/${pullStocksTotal}`)
        showSuccess(`Отправка остатков: ${pushMsSuccess}/${pushMsTotal}`)
        showSuccess(`Получение товаров: ${productsSuccess}/${productsTotal}`)
      } finally {
        setRefreshed()
      }
    }

    return (
      <Box display="flex" flexDirection="column" alignItems="flex-start" marginBottom={2}>
        <Box>
          <StatusSyncTable/>
        </Box>

        <Box>
          {
            isRefreshing
            ? <CircularProgress title="Обновляем..." size={20}/>
            :
            <Box display="flex" marginLeft={1}>

              <button
                className={clsx(classes.saveButton, 'default-button')}
                disabled={isRefreshing}
                onClick={onRefreshClick}>
                <p>{isRefreshing ? 'Обновляем...' : 'Обновить'}</p>
              </button>

            </Box>
          }
        </Box>
      </Box>
    )
  })