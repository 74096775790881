import React from 'react'
import { TablePagination2 } from './TablePagination'

import { ITableData } from '../types/tableTypes'
import { makeStyles } from '@material-ui/core/styles'

type OrdersTableProps<TRow extends object, TColumnId> = {
  table: ITableData<TRow, TColumnId>
}
export const OrdersTopPagination =
  <TRow extends object, TColumnId>
  ({table}: OrdersTableProps<TRow, TColumnId>) => {
    const classes = useStyles()

    return (
      <div
        style={{
          position: 'absolute',
          right: '0',
          bottom: 10
        }}
      >
        <TablePagination2
          table={table}
          labelRowsPerPage="Заказов на странице:"
          className={classes.pagination}
        />
      </div>
    )
  }

const useStyles = makeStyles(
  (theme) => ({
    pagination: {
      position: 'absolute',
      right: 90,
      color: theme.palette.mpSkladPrimary.gray,
      fontSize: 10
    }
  }))