import { cellStyleBreakNormal } from './myStorageColumns'
import { MyProductColumnGroupId } from './myProductColumnGroups'
import { MyOzonProductColumnId, MyProductColumnId, selectColumnId } from './myProductColumnIds'
import { createColumnGroup, createColumns, GroupedMyColumnsParam, MyColumnParam } from './columnGroupCreator'

import { ProductImageCell2 } from '../components/ProductImageCell'
import { TableSelectCell } from '../components/tableCells/TableSelectCell'
import { MyProductOzonSyncCell } from '../components/tableCells/MyProductOzonSyncCell'
import { OzonProductSyncLogsCell } from '../components/tableCells/StoreProductSyncLogsCell'

import { emDash } from '../common/stringUtility'
import { formatBalance, formatPrice, formatPriceOrEmpty } from '../common/numberUtils'

import { MyProductModel } from '../server/mpsklad_core/Models/MyProductModel'
import { OzonProductModel } from '../server/mpsklad_core/Models/OzonProductModel'
import { TableColumn } from './tableTypes'
import { TableDateCell } from '../components/tableCells/TableDateCell'
import { OzonPrintOrderModel } from '../server/mpsklad_core/Models/OzonPrintOrderModel'
import { formatDateOnly } from '../common/dateTimeUtility'

const cellWarningStyle = {backgroundColor: '#ffb74d'}

export const myOzonColumns =
  createColumnGroup<MyProductColumnId, MyProductModel>()(
    MyProductColumnGroupId.Ozon, [{
      title: 'Передача остатков',
      id: 'ozonSyncEnabled',
      sortable: true,
      CellComponent: MyProductOzonSyncCell
    }, {
      title: 'Артикул',
      id: 'ozonArticle',
      sortable: true,
      accessor: _ => _.ozonProducts.map(p => p.article)
    }, {
      title: 'Цена',
      id: 'ozonPrice',
      accessor: _ => _.ozonProducts.map(p => formatPriceOrEmpty(p.price))
    }, {
      title: 'Цена без скидки',
      id: 'ozonBasePrice',
      accessor: _ => _.ozonProducts.map(p => formatPriceOrEmpty(p.basePrice))
    }, {
      title: 'Остаток',
      id: 'ozonBalance',
      accessor: _ => _.ozonProducts.map(p => formatBalance(p.balance))
    }, {
      title: 'Выставлен на продажу',
      id: 'ozonIsVisible',
      sortable: true,
      accessor: _ => _.ozonProducts.map(p => p.isVisible),
      cellStyle: _ => _.ozonProducts.some(p => !p.isVisible) ? cellWarningStyle : undefined
    }, {
      title: 'В архиве',
      id: 'ozonIsArchived',
      accessor: _ => _.ozonProducts.map(p => p.isArchived == null ? emDash : p.isArchived ? 'Да' : 'Нет'),
      cellStyle: _ => _.ozonProducts.some(p => p.isArchived) ? cellWarningStyle : undefined
    }] satisfies GroupedMyColumnsParam<MyOzonProductColumnId, MyProductModel>) // Ensure filtering of ids

export type OzonProductColumnId =
  keyof Pick<OzonProductModel, 'imageUrl' | 'article' | 'barcodes' | 'name' | 'price' | 'basePrice' | 'balance'
    | 'isVisible' | 'isArchived' | 'hasMsProduct'>
  | 'logs' | typeof selectColumnId

const ozonProductCommonColumns = [{
  id: 'imageUrl',
  title: 'Фото',
  CellComponent: ProductImageCell2
}, {
  id: 'article',
  title: 'Артикул'
}, {
  id: 'barcodes',
  title: 'Штрихкод',
  cellStyle: cellStyleBreakNormal
}, {
  id: 'name',
  title: 'Наименование'
}, {
  id: 'price',
  title: 'Цена',
  accessor: _ => formatPriceOrEmpty(_.price)
}, {
  id: 'basePrice',
  title: 'Цена без скидки',
  accessor: _ => formatPriceOrEmpty(_.basePrice)
}, {
  id: 'balance',
  title: 'Остаток',
  accessor: _ => formatBalance(_.balance)
}, {
  id: 'isVisible',
  title: 'Выставлен на продажу',
  cellStyle: _ => !_.isVisible ? cellWarningStyle : undefined
}, {
  id: 'isArchived',
  title: 'В архиве',
  accessor: _ => _.isArchived == null ? emDash : _.isArchived ? 'Да' : 'Нет'
}, {
  id: 'hasMsProduct',
  title: 'Сматчен с МоимСкладом'
}] satisfies Array<MyColumnParam<OzonProductColumnId, OzonProductModel>>

export const ozonProductColumns =
  createColumns<OzonProductColumnId, OzonProductModel>(
    ...ozonProductCommonColumns, {
      id: 'logs',
      title: 'Логи',
      CellComponent: OzonProductSyncLogsCell
    })

export const ozonProductImportColumns =
  createColumns<OzonProductColumnId, OzonProductModel>({
    id: selectColumnId,
    title: 'Выбрать',
    CellComponent: TableSelectCell
  }, ...ozonProductCommonColumns)

export type OzonPrintOrderColumnId =
  keyof Pick<OzonPrintOrderModel, 'createdAt' | 'shipmentDate' | 'ozonId' | 'postingNumber' | 'price'>

export type OzonPreviewOrderColumn = TableColumn<OzonPrintOrderColumnId, OzonPrintOrderModel>

// TODO: Allow sorting? Requires backend.
export const ozonPrintOrderColumns: OzonPreviewOrderColumn[] =
  createColumns<OzonPrintOrderColumnId, OzonPrintOrderModel>({
    id: 'createdAt',
    title: 'Дата создания',
    CellComponent: TableDateCell
  }, {
    id: 'shipmentDate',
    title: 'Плановая дата отгрузки',
    accessor: _ => _.shipmentDate ? formatDateOnly(new Date(_.shipmentDate)) : '-'
  }, {
    id: 'ozonId',
    title: 'Id в Ozon'
  }, {
    id: 'postingNumber',
    title: 'Номер отправления'
  }, {
    id: 'price',
    title: 'Цена продажи, ₽',
    accessor: _ => formatPrice(_.price)
  })