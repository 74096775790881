import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'

import { Box, FormHelperText, FormLabel, Grid, Typography } from '@material-ui/core'
import clsx from 'clsx'

import { useMessages } from '../../hooks/snackbarHooks'
import { useLogic } from '../../hooks/storeHook'
import { useBoolState, useToggleState } from '../../hooks/commonHooks'

import { required } from '../../common/objectUtils'
import { valuesOfNumericEnum } from '../../common/tsUtils'
import { commissionsRecordingTypeNames } from '../../types/commissionsRecordingTypeNames'

import { StoreAccountModelBase } from '../../server/mpsklad_core/Models/StoreAccountModelBase'
import { CommissionsRecordingType } from '../../server/mpsklad_core/Entity/Base/CommissionsRecordingType'

import { IOSSwitch } from '../IOSSwitch'
import { useSettingsPageStyles } from '../../hooks/settingsPageStylesHook'
import { DefaultSelect } from '../DefaultSelect'

export type CommissionsSettingsProps = {
  account: StoreAccountModelBase
}
export const CommissionsSettings =
  observer(({account}: CommissionsSettingsProps) => {
    const {showSuccess, showError} = useMessages()

    const logic = useLogic()

    const accountId = required(account.id)

    const [isLoading, setIsLoading, setNotLoading] = useBoolState()

    const [isUsingCommissions, toggleIsUsingCommissions] = useToggleState(account?.isUsingCommissions ?? false)

    const [ordersCommissionsRecording, setOrdersCommissionsRecording] =
      useState(account.ordersCommissionsRecording ?? CommissionsRecordingType.Nothing)

    const [documentsCommissionsRecording, setDocumentsCommissionsRecording] =
      useState(account.documentsCommissionsRecording ?? CommissionsRecordingType.Nothing)

    const classes = useSettingsPageStyles()

    const onSubmit = async () => {
      try {
        setIsLoading()

        await logic.setCommissionsSettings({
          accountId,
          isUsingCommissions,
          ordersCommissionsRecordingType: ordersCommissionsRecording,
          documentsCommissionsRecording: documentsCommissionsRecording,
          integrationType: account.integrationType
        })

        showSuccess('Настройки комиссий обновлены!')
      } catch (e) {
        showError('Произошла ошибка при настройке комиссий!')
      } finally {
        setNotLoading()
      }
    }

    return (
      <Box className={classes.pageContainer}>
        <Typography className={classes.header}>НАСТРОЙКИ КОМИССИЙ</Typography>
        <Grid container spacing={3}>
          <Grid item xs={7}>
            <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
              <Grid item className={classes.root} xs={12}>

                <Box width={'100%'} display="flex" justifyContent="space-between" alignItems="center">
                  <FormLabel className={classes.unifiedPricesLabel}>
                    Автоматический учёт комиссий
                  </FormLabel>
                  <IOSSwitch checked={isUsingCommissions} onChange={toggleIsUsingCommissions}/>
                </Box>

                <FormHelperText className={classes.helperText}>
                  Обновляется вместе с заказами
                </FormHelperText>

              </Grid>
            </Grid>
          </Grid>
          {isUsingCommissions && <>
            <Grid item xs={7}>
              <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
                <Grid item className={classes.root} xs={12}>

                  <Box>
                    <FormLabel component="legend" className={classes.label}>
                      <span className={classes.dash}>—</span>
                      Учитывать в документах
                    </FormLabel>
                  </Box>

                  <DefaultSelect<CommissionsRecordingType>
                    value={documentsCommissionsRecording}
                    onChange={(value) => setDocumentsCommissionsRecording(value as CommissionsRecordingType)}
                    options={valuesOfNumericEnum(CommissionsRecordingType).map(value => ({
                      value: value,
                      label: commissionsRecordingTypeNames[value]
                    }))}
                    fullWidth
                  />

                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={7}>
              <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
                <Grid item className={classes.root} xs={12}>

                  <Box>
                    <FormLabel component="legend" className={classes.label}>
                      <span className={classes.dash}>—</span>
                      Учитывать в заказах
                    </FormLabel>
                  </Box>

                  <DefaultSelect<CommissionsRecordingType>
                    value={ordersCommissionsRecording}
                    onChange={(value) => setOrdersCommissionsRecording(value ?? CommissionsRecordingType.Nothing)}
                    options={[
                      {
                        value: CommissionsRecordingType.Nothing,
                        label: commissionsRecordingTypeNames[CommissionsRecordingType.Nothing]
                      },
                      {
                        value: CommissionsRecordingType.Commission,
                        label: commissionsRecordingTypeNames[CommissionsRecordingType.Commission]
                      }
                    ]}
                    fullWidth
                    placeholder="Выберите вариант"
                  />

                </Grid>
              </Grid>
            </Grid>
          </>
          }
        </Grid>
        <Box display="flex">

          <button
            className={clsx(classes.saveButton, 'default-button')}
            disabled={isLoading}
            onClick={onSubmit}>
            <p>{isLoading ? 'Сохраняем...' : 'Сохранить'}</p>
          </button>

        </Box>
      </Box>
    )
  })