import React, { FormEvent, useState } from 'react'
import { reaction } from 'mobx'
import { observer } from 'mobx-react-lite'

import { Box, FormLabel, Grid } from '@material-ui/core'

import { useMessages } from '../hooks/snackbarHooks'
import { useLogic, useStore } from '../hooks/storeHook'
import { useBoolState, useLazyEffect } from '../hooks/commonHooks'
import clsx from 'clsx'
import { useSettingsPageStyles } from '../hooks/settingsPageStylesHook'
import { BootstrapInput } from './BootstrapInput'

export const MoySkladAccountForm =
  observer(
    () => {
      const {showSuccess} = useMessages()
      const {syncStore} = useStore()
      const {moySkladAccount} = syncStore

      const logic = useLogic()
      const [isLoading, setIsLoading, setNotLoading] = useBoolState()

      const [accessToken, setAccessToken] = useState(moySkladAccount?.accessToken ?? '')

      const classes = useSettingsPageStyles()

      useLazyEffect(
        () => reaction(
          // NOTE: Access through the syncStore to trigger the reaction
          () => syncStore.moySkladAccount?.accessToken,
          accessToken => setAccessToken(accessToken ?? '')
        ))

      const onSubmit =
        async (e: FormEvent) => {
          e.preventDefault()
          setIsLoading()

          try {
            await logic.editMoySkladAccount({accessToken})
            showSuccess('Сохранено!')
          } catch (e) {
            console.error('Failed to save MoySklad account', e)
          } finally {
            setNotLoading()
          }
        }

      return (
        <Box className={classes.pageContainer}>
          <Grid container className={clsx(classes.settingsTopic, 'default-border')}>
            <Grid item xs={12}>
              <Box>
                <FormLabel component="legend" className={classes.boldLabel}>
                  API токен
                </FormLabel>
              </Box>

              <Box>

                <BootstrapInput
                  required
                  id="accessToken"
                  placeholder="be0813e9"
                  value={accessToken}
                  onChange={e => setAccessToken(e.target.value)}
                  fullWidth
                />

              </Box>

              <button
                className={clsx(classes.saveButton, 'default-button')}
                disabled={isLoading}
                onClick={onSubmit}>
                <p>{isLoading ? 'Сохраняем...' : 'Сохранить'}</p>
              </button>
            </Grid>
          </Grid>
        </Box>
      )
    })