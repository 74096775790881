import React, { FormEvent, useState } from 'react'
import { observer } from 'mobx-react-lite'

import { Box, FormHelperText } from '@material-ui/core'
import { BootstrapInput } from './BootstrapInput'

import { useLogic } from '../hooks/storeHook'
import { useBoolState } from '../hooks/commonHooks'
import { useMessages } from '../hooks/snackbarHooks'
import { useAuthFormStyles } from '../hooks/authFormStylesHook'
import { useSettingsPageStyles } from '../hooks/settingsPageStylesHook'
import clsx from 'clsx'

export const PasswordResetEmailForm = observer(() => {
  const formClasses = useAuthFormStyles()
  const classes = useSettingsPageStyles()

  const logic = useLogic()
  const {showSuccess} = useMessages()

  const [email, setEmail] = useState('')
  const [isLoading, setLoading, setFinished] = useBoolState()

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()

    setLoading()

    try {
      await logic.sendPasswordResetCodeEmail(email)
      showSuccess('Код восстановления отправлен на почту')
    } catch (e) {
      setFinished()
    }
  }

  return (
    <>
      <Box className={clsx(formClasses.container, classes.settingsTopic, 'default-border')}>
        <form className={formClasses.form} onSubmit={onSubmit}>
          <FormHelperText className={classes.helperText}>
            <span className={classes.dash}>—</span> Email
            <span className={clsx(classes.star, classes.star)}>*</span>
          </FormHelperText>
          <BootstrapInput
            id="email"
            onChange={(e) => setEmail(e.target.value)}
            inputProps={{'aria-label': 'Email'}}
            fullWidth
          />
        </form>
      </Box>

      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" mt={2}>
        <button
          className={clsx(classes.saveButton, 'default-button')}
          disabled={isLoading}
          onClick={onSubmit}
        >
          <p>{isLoading ? '...' : 'Получить код'}</p>
        </button>
      </Box>
    </>
  )
})