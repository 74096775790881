import React, { FormEvent, useState } from 'react'
import { observer } from 'mobx-react-lite'

import { Box, FormHelperText } from '@material-ui/core'

import { useLogic } from '../hooks/storeHook'
import { useBoolState } from '../hooks/commonHooks'
import { useMessages } from '../hooks/snackbarHooks'
import { useAuthFormStyles } from '../hooks/authFormStylesHook'
import clsx from 'clsx'
import { BootstrapInput } from './BootstrapInput'
import { useSettingsPageStyles } from '../hooks/settingsPageStylesHook'

export const PasswordResetCodeForm =
  observer(() => {
    const formClasses = useAuthFormStyles()
    const classes = useSettingsPageStyles()

    const logic = useLogic()

    const {showSuccess} = useMessages()

    const [resetCode, setResetCode] = useState('')
    const [isLoading, setLoading, setFinished] = useBoolState()

    const onSubmit =
      async (e: FormEvent) => {
        e.preventDefault()

        setLoading()

        try {
          await logic.verifyResetCode(resetCode)
          showSuccess('Код проверен!')
        } catch (e) {
          // NOTE: Don't update state on success - this component will be unmounted
          setFinished()
        }
      }

    return (
      <>
        <Box className={clsx(formClasses.container, classes.settingsTopic, 'default-border')}>
          <form className={formClasses.form}>
            <FormHelperText className={classes.helperText}>
              <span className={classes.dash}>—</span> Проверочный код
              <span className={clsx(classes.star, classes.star)}>*</span>
            </FormHelperText>
            <BootstrapInput
              id="email"
              onChange={(e) => setResetCode(e.target.value)}
              inputProps={{'aria-label': 'Email'}}
              fullWidth
            />
          </form>
        </Box>

        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" mt={2}>
          <button
            className={clsx(classes.saveButton, 'default-button')}
            disabled={isLoading}
            onClick={onSubmit}
          >
            <p>{isLoading ? '...' : 'Проверить код'}</p>
          </button>
        </Box>
      </>
    )
  })