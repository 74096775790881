import React from 'react'
import { observer } from 'mobx-react-lite'

import { TablePagination2 } from './TablePagination'

import { useStore } from '../hooks/storeHook'
import { makeStyles } from '@material-ui/core/styles'

export const MyProductsTopPagination =
  observer(() => {
    const {productStore: {myProducts}} = useStore()
    const classes = useStyles()

    return (
      <div
        style={{
          position: 'absolute',
          top: '80px',
          right: '0'
        }}
      >
        <TablePagination2
          table={myProducts}
          labelRowsPerPage="Товаров на странице:"
          className={classes.pagination}
        />
      </div>
    )
  })

const useStyles = makeStyles(
  (theme) => ({
    pagination: {
      position: 'absolute',
      top: 0,
      right: 90,
      color: theme.palette.mpSkladPrimary.gray,
      fontSize: 10
    }
  }))